import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Box, Typography, Button, Grid } from '@mui/material';
import { Assessment, EnergySavingsLeaf as Eco, Storage } from '@mui/icons-material';



const LandingPage = () => {
  const history = useHistory();
  const videoRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const setBoxHeight = () => {
      if (videoRef.current && boxRef.current) {
        boxRef.current.style.height = `${videoRef.current.offsetHeight}px`;
      }
    };
    
    setBoxHeight();
    window.addEventListener('resize', setBoxHeight);

    return () => {
      window.removeEventListener('resize', setBoxHeight);
    };
  }, []);

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box 
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: 'calc(100vh - 64px)', // Adjust for the NavBar height
          '@media (max-width:600px)': {
            minHeight: 'calc(100vh - 56px)', // Adjust for the mobile NavBar height
          },
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        <video ref={videoRef} autoPlay loop muted style={{ position: 'absolute', width: '100%', height: 'auto', objectFit: 'cover', zIndex: 0 }}
        poster={`${process.env.PUBLIC_URL}/nature_first_frame.png`}
        >
          <source src={`${process.env.PUBLIC_URL}/nature.mp4`} type="video/mp4" />
        </video>
        <Box 
          ref={boxRef}
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: '#fff',
            // background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Welcome to Our GHG Protocol Reporting Site!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" startIcon={<Assessment />} size="large" onClick={() => navigateTo('/reports')}>
                Reports
              </Button>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                View and manage reports.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" startIcon={<Eco />} size="large" onClick={() => navigateTo('/initiatives')}>
                Initiatives
              </Button>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Discover our initiatives.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" startIcon={<Storage />} size="large" onClick={() => navigateTo('/data-collection')}>
                Data Collection
              </Button>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Start collecting data.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LandingPage;