import { ref, child, get } from 'firebase/database';
import { 
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from 'firebase/auth';
import { database, auth } from 'firebase_config'

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';



const dbRef = ref(database);
const users = child(dbRef, 'users/users')

// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values, cb) {
    const { email, password } = values;
    return () => signInWithEmailAndPassword(auth, email, password)
    .then(res=>{
            cb({type: 'User', res});
            })
        .catch(res=>cb({type: 'Error', res}))
}


export function logoutUser(cb) {
    return dispatch=>signOut(auth)
    .then(cb)
    .catch(err=>cb(err))
}

export function verifyAuth() {
    return dispatch=>{
        const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
        onAuthStateChanged(auth, user=>{
            if (user && user.uid) {
                const uid = user.uid;
                const uChild = child(users, uid);
                get(uChild).then(snapshot=>{
                    const dbUser = snapshot.val()
                    const userString = JSON.stringify(user)
                    window.localStorage.setItem(key, userString)
                    console.log('logged in')
                    dispatch({
                        type: AUTH_USER,
                        payload: {...user, ...dbUser}
                    })
                })
                
            } else {
                window.localStorage.removeItem(key)
                dispatch({
                    type: UNAUTH_USER
                })
            }
        });
    };
}


