import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import rootReducer from 'reducers';
import 'index.css';
import App from 'App';
import SignIn from 'components/signin';
import Landing from 'components/landing';
import NavBar from 'components/navbar';
import PrivateRoute from 'components/auth';
import * as serviceWorker from 'serviceWorker';

import { verifyAuth } from './actions';

export function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose (
            applyMiddleware(thunk),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );
    
    store.dispatch(verifyAuth())

    return store;
}

const store = configureStore();

let theme = createTheme({
    palette: {
        primary: {
            light: '#1667b8',
            main: '#0f4b87',
            dark: '#043566'
        },
        secondary: {
            light: '#ffb477',
            main: '#f8923d',
            dark: '#e36802'
        }
    }
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
                //opacity: 0.5,
        },
      },
    },
  },
});

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={4}>
            <ThemeProvider theme={theme}>
                {/*<CssBaseline />*/}
                <BrowserRouter>
                    <NavBar />
                    <Switch>
                        <PrivateRoute exact path='/main' component={App} />
                        <Route path='/signin' component={SignIn} />
                        <Route path='/' component={Landing} />
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </SnackbarProvider>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
