import { AUTH_USER, UNAUTH_USER } from 'actions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case AUTH_USER:
            return action.payload;
        case UNAUTH_USER:
            return INITIAL_STATE
        default:
            return state;
    }
}